import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { fetchUsers } from "../../api/user";
import Logger from "../../common/utils/Logger";
import NtUserItem from "./NtUserItem";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import settings from "../../config/settings";

function NtUsersController({ onPress, searchFilter }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllUsers = async (page, pageSize, searchFilter) => {
    Logger("Will request the users for page and size", page, pageSize);
    setLoading(true);
    const response = await fetchUsers(page, pageSize, searchFilter);
    if (response.ok) {
      var items = [];
      response.data._embedded.user
        .filter((each) => each.id !== 1)
        .map((each) => {
          items.push({
            id: each.id,
            name: each.firstname + " " + each.lastname,
            number: each.id,
            email: each.email,
          });
        });

      Logger("Just finished fetching the users... ", items);
      if (page === 1) {
        Logger("First time loading");
        setData(items);
      } else {
        Logger("Adding more");
        setData(data.concat(items));
      }
    }
    //finish loading
    setLoading(false);
  };

  const renderRow = (each, index) => {
    return (
      <NtUserItem
        onPress={(user) => {
          if (onPress) {
            onPress(user);
          }
        }}
        key={each.id}
        user={each}
      />
    );
  };

  useEffect(() => {
    fetchAllUsers(1, settings.pageSizeLoad, searchFilter);
  }, []);

  return (
    <View style={styles.container}>
      {data.map((each, index) => renderRow(each, index))}

      <View>
        <NtLoadMoreButton
          loadingText={"Loading"}
          loading={loading}
          containerStyle={{ marginTop: 40, marginBottom: 40 }}
          onPress={() => {
            const page = Math.round(data.length / settings.pageSizeLoad) + 1;
            fetchAllUsers(page, settings.pageSizeLoad, searchFilter);
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtUsersController;
