import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtTextInput from "../../components/NtTextInput";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtMessageSendController({
  containerStyle,
  onSend,
  isLoading = false,
  ...otherProps
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}
      >
        <NtTextInput
          containerStyle={{ flex: 1, borderRadius: 30 }}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          textColor={colors.darkest}
          {...otherProps}
        />

        <View
          style={{
            position: "absolute",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtTouchableEffect
            style={styles.button}
            onPress={() => {
              if (onSend) {
                onSend();
              }
            }}
          >
            {!isLoading && (
              <NtMaterialIcon name="send" size={25} color={"white"} />
            )}
            {isLoading && <NtActivityIndicator size="small" color="white" />}
          </NtTouchableEffect>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: 40,
    width: 40,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.lighGray,
    marginLeft: 5,
  },
});

export default NtMessageSendController;
