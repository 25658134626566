import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";

const endpoint = "/chat/history";

export const fetchChats = (id) => {
  return client.get(endpoint + "?conversationId=" + id);
};

export const fetchHistory = (from, to) => {
  // const endpoint =
  //   "/chat/history" +
  //   parseQueryParams({
  //     user: `${generateFullUser(from)},${generateFullUser(to)}`,
  //   });

  const endpoint =
    "/chat/history" +
    parseQueryParams({
      user: `${generateFullUser(from)}`,
    });

  return client.get(endpoint);
};
