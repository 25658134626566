import client, { parseQueryParams } from "./client";
import Logger from "../common/utils/Logger";

const endpoint = "/user?page_size=5000";

export const fetchUsers = (page = 1, page_size = 25, filter = null) => {
  let params = { page: page, page_size: page_size };

  if (filter) {
    params = { ...params, ...filter };
  }

  const endpoint = "/user" + parseQueryParams(params);
  return client.get(endpoint);
};
