import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import { sipPropType, SipProvider, SIP_STATUS_CONNECTED } from "react-sip";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import JsSIP from "jssip";

class DialpadController extends React.Component {
  state = {};

  testme = () => {
    var socket = new JsSIP.WebSocketInterface("wss://172.16.86.220");
    var configuration = {
      sockets: [socket],
      uri: "sip:100@nettalk.pbx.nettalk.com",
      password: "password1A",
    };

    var ua = new JsSIP.UA(configuration);

    ua.start();

    // // Register callbacks to desired call events
    // var eventHandlers = {
    //   progress: function (e) {
    //     console.log("call is in progress");
    //   },
    //   failed: function (e) {
    //     console.log("call failed with cause: " + e.data.cause);
    //   },
    //   ended: function (e) {
    //     console.log("call ended with cause: " + e.data.cause);
    //   },
    //   confirmed: function (e) {
    //     console.log("call confirmed");
    //   },
    // };

    // var options = {
    //   eventHandlers: eventHandlers,
    //   mediaConstraints: { audio: true, video: true },
    // };

    // var session = ua.call("sip:bob@example.com", options);
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={true}
        showFooter={true}
        pageTitle={"Dialpad"}
      >
        <NtTouchableEffect
          onPress={() => {
            this.testme();
          }}
        >
          <NtText>Try me</NtText>
        </NtTouchableEffect>

        {/* <SipProvider
          host="172.16.86.220"
          port={7443}
          pathname="/ws" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
          user="100"
          password={"password1A"} // usually required (e.g. from ENV or props)
          autoRegister={true} // true by default, see jssip.UA option register
          autoAnswer={false} // automatically answer incoming calls; false by default
          iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
          sessionTimersExpires={120} // value for Session-Expires header; 120 by default
          extraHeaders={{
            // optional sip headers to send
            register: ["X-Foo: foo", "X-Bar: bar"],
            invite: ["X-Foo: foo2", "X-Bar: bar2"],
          }}
          // iceServers={[ // optional
          //   { urls: ['stun:a.example.com', 'stun:b.example.com'] },
          //   { urls: 'turn:example.com', username: 'foo', credential: '1234' }
          // ]}
          debug={true} // whether to output events to console; false by default
        >
          <NtText>Inside of the Dialer{SIP_STATUS_CONNECTED}</NtText>
        </SipProvider> */}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default DialpadController;
