import React from "react";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import MessagingClient from "../utils/MessagingClient";
import Logger from "../../common/utils/Logger";
import NtButton from "../../components/NtButton";
import RecordItem from "../utils/Message/RecordItem";
import MessageItem from "../utils/Message/MessageItem";
import BaseController from "../../common/base/BaseController";
import NtMessageSendController from "../components/NtMessageSendController";
import NtChatBubble from "../components/NtChatBubble";
import colors from "../../config/colors";
import NtConnectionStatus from "../components/NtConnectionStatus";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import { fetchHistory } from "../../api/chat";

class ChatController extends BaseController {
  state = {
    messages: [],
    messageText: "",
    isSending: false,
    isOnline: false,
    isFetchingHistory: false,
  };

  client = new MessagingClient();

  componentDidMount() {
    if (this.client.isConnected) {
      this.handleSubscribe();
    } else {
      this.client.addConnectionCallback((connection) => {
        Logger("Connection callback: ", connection);
        if (connection.connected) {
          this.handleSubscribe();
        }
      });
    }
  }

  handleHistyFetch = async () => {
    this.setState({ isFetchingHistory: true });
    const response = await fetchHistory(
      this.context.user.id,
      this.props.route.params.id
    );
    Logger("--- here is the history: ", response);

    // if (response.ok && Array.isArray(response.data._embedded.chat_history)) {
    //   var messages = [];
    //   response.data._embedded.chat_history.map((each) => {
    //     messages = [...messages, each.payload];
    //   });

    //   // will handle the to for the message:
    //   if (messages.length > 0) {
    //     const messageObj = messages[0];
    //     if (messageObj) {
    //       this.setState({
    //         recepients: [
    //           ...this.state.recepients,
    //           messageObj.address.substr(0, messageObj?.address.indexOf("@")),
    //         ],
    //       });
    //     }
    //   }

    //   console.log("Here is the history", messages);

    //   //finally will set all the messages.....
    //   this.setState({ messages: messages });

    //   if (messages.length > 5) {
    //     await this.delay(200);
    //     // this.messageListRef.current.scrollToEnd();
    //   }
    // }
    // this.setState({ loading: false });
    this.setState({ isFetchingHistory: false });
  };

  handleSubscribe = () => {
    this.client.subscribe(this.context.user, this.onMessageReceived);
    this.setState({ isOnline: true });
  };

  handleMessageSend = () => {
    this.setState({ isSending: true });
    const record = new RecordItem(this.props.route.params.id.toString());
    const message = new MessageItem(
      this.context.user,
      record.getAddress(),
      this.state.messageText
    );

    this.client.sendMessage(message, record, this.onMessageSent);
  };

  onMessageSent = (message) => {
    Logger("Message sent", message);
    this.setState({
      messageText: "",
      messages: [...this.state.messages, message],
      isSending: false,
    });
  };

  onMessageReceived = (message) => {
    Logger("Message Received ", message);

    this.setState({
      messages: [...this.state.messages, message],
    });
  };

  renderItem = (item, index) => {
    return <NtChatBubble message={item} />;
  };

  renderHeader = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 25,
          marginBottom: 20,
        }}
      >
        <NtText style={styles.headerText}>
          Conversation with {this.props.route.params.id.toString()}{" "}
          <NtConnectionStatus containerStyle={{ marginLeft: 6 }} />
        </NtText>
        {this.state.isFetchingHistory && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <NtText style={styles.headerText}>
              Loading messages from history{" "}
            </NtText>
            <NtActivityIndicator color={colors.lighGray} size={"small"} />
          </View>
        )}
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        renderTitleView={<View />}
        showNavigation={true}
        showFooter={false}
        scrollEnabled={false}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            <FlatList
              scrollEnabled={true}
              contentContainerStyle={{ flex: 1 }}
              style={{ height: 300 }}
              data={this.state.messages}
              showsVerticalScrollIndicator={false}
              ListHeaderComponent={this.renderHeader()}
              ItemSeparatorComponent={() => {
                return <View style={{ height: 4 }} />;
              }}
              keyExtractor={(index, item) => item?.toString()}
              renderItem={({ item, index }) => this.renderItem(item, index)}
            />
          </View>

          <NtMessageSendController
            placeholder={"Message to send"}
            value={this.state.messageText}
            onChange={(e) => {
              this.setState({ messageText: e.target.value });
            }}
            onSend={() => {
              this.handleMessageSend();
            }}
            isLoading={this.state.isSending}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  headerText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default ChatController;
