import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Modal,
  Pressable,
  TouchableWithoutFeedback,
} from "react-native";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";
import Logger from "../common/utils/Logger";

const NtModal = React.forwardRef(({ renderBody, containerStyle }, ref) => {
  // function NtModal({ renderBody }) {
  const [visible, setVisible] = useState(false);

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
    dismiss: () => {
      setVisible(false);
    },
  }));

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => {}}
      >
        <NtTouchableEffect
          activeOpacity={1}
          onPress={() => {
            setVisible(false);
          }}
          style={styles.centeredView}
        >
          <NtTouchableEffect
            activeOpacity={1}
            style={[styles.modalView, containerStyle]}
          >
            {renderBody}
          </NtTouchableEffect>
        </NtTouchableEffect>
      </Modal>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {},
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default NtModal;
