import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";

function NtPartnerItem({ item }) {
  return (
    <View style={styles.container}>
      <Image
        style={[styles.image, { width: item.width, height: item.height }]}
        source={item.image}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    resizeMode: "contain",
  },
});

export default NtPartnerItem;
