import client from "./client";

export const sendBroadcast = (message) => {
  const data = new FormData();
  data.append("message", message);
  data.append("extras", "{}");
  data.append("options", "{}");

  return client.post("/messaging/broadcast", data);
};

export const sendSystemMessage = (message, to) => {
  const data = new FormData();

  data.append("to", to);
  data.append("message", message);
  data.append("extras", "{}");
  data.append("options", "{}");

  return client.post("/messaging/system/message", data);
};
