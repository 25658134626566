import React, { useContext, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtNavigationButton from "./NtNavigationButton";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import NtButton from "../../components/NtButton";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NavigationBar({ navItems, currentController, onNavPress }) {
  const context = useContext(UserContext);
  // const [selection, setSelection] = useState(navItems[0].route);
  const nav = useNavigation();
  const isMobile = useMobile();

  const renderNavItem = (item) => {
    return (
      <View key={item.name} style={{ flexDirection: "row", marginRight: 30 }}>
        <NtNavigationButton
          isMobile={isMobile}
          title={item.name}
          icon={item.icon}
          selected={currentController === item.route}
          onPress={() => {
            nav.navigate(item.route);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtTouchableEffect
        onPress={() => {
          nav.navigate(routes.marketing);
        }}
      >
        <Image
          style={styles.image}
          source={require("../../assets/images/nt-maritime-logo-white.png")}
        />
      </NtTouchableEffect>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {navItems && (
          <View style={{ flexDirection: "row" }}>
            {navItems && navItems.map((each) => renderNavItem(each))}
          </View>
        )}

        {context.user.authenticated && (
          <NtNavigationButton
            isMobile={isMobile}
            title={`Logout (${context.user.id})`}
            icon={"logout"}
            onPress={() => {
              if (context.user.authenticated) {
                context.updateUser({});
              }

              setTimeout(() => {
                nav.navigate(routes.auth);
              }, 500);
            }}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: colors.navigationColor,
    elevation: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20,
  },
  image: {
    height: 40,
    width: 150,
    resizeMode: "contain",
  },
});

export default NavigationBar;
