import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtPartnerItem from "./NtPartnerItem";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";
import NtPartnerHAItem from "./NtPartnerHAItem";

function NtPartnersSection({}) {
  const isMobile = useMobile();

  const iconWidth = (isAH = false, altWidth = 0) => {
    return isMobile ? (isAH ? 160 : 180) : 220 + altWidth;
  };

  const iconHeight = (isAH = false, altHeight = 0) => {
    return isMobile ? (isAH ? 48 : 54) : 66 + altHeight;
  };

  const partnerHA = [
    {
      image: require("../../assets/images/partners/princess.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/holland.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/p-o-australia.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/seabourn.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
  ];

  const partners = [
    {
      image: require("../../assets/images/partners/ncl.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/disney.png"),
      width: iconWidth(false, 40),
      height: iconHeight(false, 18),
    },

    {
      image: require("../../assets/images/partners/crystal.png"),
      width: iconWidth(false, -20),
      height: iconHeight(false, -6),
    },
    {
      image: require("../../assets/images/partners/paradise.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/canadian-coast-guard.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/inmarsat.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/oracle.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/tritan.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/bluetooth.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/suse.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
  ];

  const renderRow = (item, index) => {
    return <NtPartnerItem item={item} />;
  };

  return (
    <NtSectionContainer backgroundColor={"white"}>
      <NtText style={[styles.title, { marginBottom: 30 }]}>Our Partners</NtText>
      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtPartnerHAItem items={partnerHA} />

        <FlatList
          style={styles.flatlist}
          data={partners}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 25 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderRow(item, index)}
          numColumns={2}
        />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 25,
    fontWeight: "700",
    color: colors.darkBlue,
  },
});

export default NtPartnersSection;
