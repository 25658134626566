import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import Base64 from "Base64";

function NtChatBubble({ message }) {
  const isIncoming = message.mType === 0;
  Logger("------------ image decoded: ", Base64.atob(message.mMedia));
  const renderBobble = () => {
    return (
      <View
        style={[
          styles.bubble,
          isIncoming
            ? { backgroundColor: colors.lighestGray }
            : { backgroundColor: colors.blue },
        ]}
      >
        <NtText
          style={[styles.messageText, isIncoming ? {} : { color: "white" }]}
        >
          {message.mMessageText}
        </NtText>
        {message.mMediaType === 2 && (
          <View style={{ marginTop: 10 }}>
            <img
              width="200"
              height="200"
              src={`data:image/png;base64,${message.mMedia}`}
            />
          </View>
        )}
      </View>
    );
  };

  return (
    <View
      style={[
        styles.container,
        { alignItems: isIncoming ? "flex-start" : "flex-end" },
      ]}
    >
      {renderBobble()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end",
  },
  bubble: {
    flexShrink: 1,
    backgroundColor: "red",
    padding: 8,
    borderRadius: 4,
  },
  messageText: {
    fontSize: 14,
    fontWeight: "500",
  },
});

export default NtChatBubble;
