import React, { useEffect } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import { useNavigation } from "@react-navigation/native";
import { useMobile } from "../../hooks/useMobile";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtFooterBar({ onContactUs }) {
  const nav = useNavigation();
  const isMobile = useMobile();

  const legal = [
    {
      name: "Terms of Service",
      action: () => {
        nav.navigate(routes.terms);
      },
    },
    {
      name: "Privacy Policy",
      action: () => {
        nav.navigate(routes.privacy);
      },
    },
    {
      name: "Cookies Policy",
      action: () => {
        nav.navigate(routes.cookies);
      },
    },
  ];

  const contact = [
    {
      name: "Contact us",
      action: () => {
        nav.navigate(routes.marketing, { contactus: true });
      },
    },
  ];

  const social = [
    // {
    //   renderIcon: (
    //     <NtMaterialIcon size={20} color={"white"} name={"facebook"} />
    //   ),
    //   name: "Facebook",
    //   action: () => {
    //     window.open("https://www.facebook.com/noozinsiders");
    //   },
    // },
    {
      renderIcon: (
        <NtMaterialIcon size={20} color={"white"} name={"linkedin"} />
      ),
      name: "LinkedIn",
      action: () => {
        window.open("https://www.linkedin.com/company/nettalkmaritime/");
      },
    },
    // {
    //   renderIcon: <NtMaterialIcon size={20} color={"white"} name={"twitter"} />,
    //   name: "Twitter",
    //   action: () => {
    //     window.open("https://twitter.com/nooz_ai");
    //   },
    // },
    // {
    //   renderIcon: (
    //     <NtMaterialIcon size={20} color={"white"} name={"instagram"} />
    //   ),
    //   name: "Instagram",
    //   action: () => {
    //     window.open("https://www.instagram.com/nooz_ai/");
    //   },
    // },
    // {
    //   renderIcon: <NtMaterialIcon size={20} color={"white"} name={"youtube"} />,
    //   name: "Youtube",
    //   action: () => {
    //     window.open("https://www.youtube.com/channel/UC2HNsfrHZlvan-SoXj6ckVQ");
    //   },
    // },
    // {
    //   renderIcon: (
    //     <Image
    //       style={{ width: 20, height: 20 }}
    //       source={require("../../assets/images/tiktok-200x200.png")}
    //     />
    //   ),
    //   name: "TikTok",
    //   action: () => {
    //     window.open("https://www.tiktok.com/@nooz_ai");
    //   },
    // },
  ];

  const renderApp = (icon, title, onPress) => {
    return (
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 20,
          borderWidth: 1,
          borderColor: "white",
          padding: 6,
          marginBottom: 10,
          width: 180,
        }}
        onPress={onPress}
      >
        <NtMaterialIcon size={20} color={"white"} name={icon} />
        <NtText style={[styles.appLink, { marginLeft: 6 }]}>{title}</NtText>
      </NtTouchableEffect>
    );
  };

  useEffect(() => {}, []);

  return (
    <View style={styles.container}>
      <View
        style={{ flex: 1, flexDirection: isMobile ? "column-reverse" : "row" }}
      >
        {/* logo container */}
        <View style={[styles.logoContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtTouchableEffect
            onPress={() => {
              nav.navigate(routes.marketing);
            }}
          >
            <Image
              style={styles.image}
              source={require("../../assets/images/nt-maritime-logo-white.png")}
            />
          </NtTouchableEffect>
        </View>

        {/* legal container */}
        <View style={[styles.legalContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtText style={[styles.sectionTitle, { marginBottom: 10 }]}>
            Legal
          </NtText>

          {legal.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect onPress={each.action}>
                <NtText style={styles.textLink}>{each.name}</NtText>
              </NtTouchableEffect>
            </View>
          ))}

          <NtText
            style={[styles.sectionTitle, { marginTop: 15, marginBottom: 10 }]}
          >
            Contact
          </NtText>

          {contact.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect
                onPress={() => {
                  each.action();
                  if (onContactUs) {
                    onContactUs();
                  }
                }}
              >
                <NtText style={styles.textLink}>{each.name}</NtText>
              </NtTouchableEffect>
            </View>
          ))}
        </View>

        {/* social container */}
        <View style={[styles.socialContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtText style={[styles.sectionTitle, { marginBottom: 10 }]}>
            Social
          </NtText>
          {social.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={each.action}
              >
                {each.renderIcon}
                <NtText style={[styles.textLink, { marginLeft: 5 }]}>
                  {each.name}
                </NtText>
              </NtTouchableEffect>
            </View>
          ))}
        </View>

        {/* app container */}
        {/* <View style={[styles.appContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtText style={[styles.sectionTitle, { marginBottom: 10 }]}>
            App
          </NtText>
          {renderApp("apple", "Apple App Store", () => {
            window.open(
              "https://apps.apple.com/us/app/nooz-ai-wise-up-to-media-bias/id1607868155"
            );
          })}
          {renderApp("google-play", "Google Play Store", () => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.heimdall.ntnooz"
            );
          })}
        </View> */}
      </View>

      <NtText style={styles.copyright}>
        Copyright © 2017 - {new Date().getFullYear()} NT MARINE APPS LLC All
        Rights Reserved.
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.navigationColor,
    padding: 30,
  },
  logoContainer: {
    padding: 10,
    alignItems: "center",
  },
  legalContainer: {
    padding: 10,
  },
  socialContainer: {
    padding: 10,
  },
  appContainer: {
    padding: 10,
  },
  sectionTitle: {
    color: colors.blue,
    fontSize: 15,
    fontWeight: "600",
  },
  appLink: {
    color: "white",
    fontWeight: "600",
    fontSize: 14,
  },

  textLink: {
    color: colors.lighter,
  },
  image: {
    width: 180,
    height: 50,
    resizeMode: "contain",
  },
  copyright: {
    color: "white",
    width: "100%",
    textAlign: "center",
    marginTop: 25,
  },
});

export default NtFooterBar;
