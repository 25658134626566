import { generateFullUser } from "../MessagingClient";
class MessageItem {
  constructor(user, address, message, data = null) {
    if (data) {
      this.mStatus = 3;
      this.mType = 0;
      this.mEncryptedMessage = false;
      this.mPayload = "message";
      this.mIsSync = false;
      this.mIsNew = true;
      this.mMediaType = data.mediatype;
      const address = data.address;
      this.mSender = data.sender;
      this.mMedia = data.media;
      this.mMessageId = data.messageid;
      this.mSenderId = data.senderid;
      if (this.mIsSync) {
        this.mType = 1;
        this.mAddress = address;
        this.mStatus = 1;
      } else if (address.includes(":")) {
        this.mAddress = address;
      } else {
        this.mAddress = this.mSender;
      }

      this.mMessageText = data.message;

      this.mPayload = data.payload;
      this.mExtras = data.extras;
    } else {
      this.mStatus = 0;
      this.mType = 1;
      this.mMediaType = 0;
      this.setAddress(address);
      this.mMessageText = message;
      this.mDate = "2022-01-13 12:27:21";
      this.mSender = generateFullUser(user.id);
      this.mUri = "";
      this.mMedia = "";
      this.mMessageId = this.generateId();
      this.mIsWellFormed = true;
      this.mPayload = "message";
      this.mIsSync = false;
      this.mIsNew = true;
      this.mSenderId = this.generateDeviceID();
      this.mExtras = "";
      this.mEncryptedMessage = false;
    }
  }

  setTopic = (topic) => {
    this.mTopic = topic;
  };

  generateId = () => {
    return Math.floor(Math.random() * 1000000000).toString();
  };

  generateDeviceID = () => {
    return "web-system";
  };

  setAddress = (address) => {
    this.mAddress = generateFullUser(address);
  };

  getData = () => {
    return {
      sender: this.mSender,
      address: this.mAddress,
      message: this.mMessageText,
      mediatype: this.mMediaType,
      media: this.mMedia,
      messageid: this.mMessageId,
      date: this.mDate,
      topic: this.mTopic,
      payload: this.mPayload,
      issync: this.mIsSync,
      senderid: this.mSenderId,
      extras: this.mExtras,
    };
  };
}

export default MessageItem;
