import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { useNavigation, useRoute } from "@react-navigation/native";
import routes from "../../navigation/routes";
import NtHoverView from "../../components/NtHoverView";

function NtRootPageNavigation({ containerStyle, otherOptions }) {
  const navigation = useNavigation();
  const route = useRoute();

  const options = [
    {
      id: routes.dashboard,
      icon: "monitor-dashboard",
      title: "Dashboard",
      action: () => {
        navigation.navigate(routes.dashboard);
      },
    },
    {
      id: routes.broadcast,
      icon: "bullhorn",
      title: "Broadcast",
      action: () => {
        navigation.navigate(routes.broadcast);
      },
    },
    {
      id: routes.systemmessage,
      icon: "message-cog",
      title: "System Message",
      action: () => {
        navigation.navigate(routes.systemmessage);
      },
    },
    {
      id: routes.message,
      icon: "message",
      title: "Message",
      action: () => {
        navigation.navigate(routes.message);
      },
    },
    {
      id: routes.voicemail,
      icon: "voicemail",
      title: "Voicemail",
      action: () => {
        navigation.navigate(routes.voicemail);
      },
    },
    {
      id: routes.dialpad,
      icon: "phone-voip",
      title: "Dialpad",
      action: () => {
        navigation.navigate(routes.dialpad);
      },
    },
  ];

  const renderItem = (item) => {
    const highlighted = item.id === route.name;

    return (
      <NtTouchableEffect
        onPress={() => {
          item.action();
        }}
        key={item.title}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtHoverView
            enabled={true}
            position={item.hoverPosition || "bottom"}
            title={item.title}
          >
            {item.renderIcon && item.renderIcon}
            {!item.renderIcon && (
              <NtMaterialIcon
                name={item.icon}
                size={22}
                color={highlighted ? colors.darkest : "#a8a8a8"}
              />
            )}
          </NtHoverView>
        </View>
      </NtTouchableEffect>
    );
  };

  return (
    <View
      style={[
        styles.container,
        { flexDirection: "row", justifyContent: "space-between" },
        containerStyle,
      ]}
    >
      {options.map((each) => renderItem(each))}

      {otherOptions && otherOptions.map((each) => renderItem(each))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: "#a8a8a8",
    fontWeight: "400",
  },
});

export default NtRootPageNavigation;
