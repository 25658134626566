import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtSearchBar from "../../components/NtSearchBar";
import NtActiveFiltersController from "../../components/NtActiveFiltersController";

class BaseMaritimeController extends BaseController {
  state = {
    searchText: "",
  };

  getParentState() {
    return this.state;
  }

  renderFilterController = () => {
    return (
      <View>
        <NtSearchBar
          containerStyle={{ flex: 1 }}
          onSearch={(text) => {
            this.handleSearch(text);
          }}
        />

        <NtActiveFiltersController
          containerStyle={{ marginTop: 10 }}
          search={this.state.searchText}
          onClearSearch={() => {
            this.handleSearch(null);
          }}
        />
      </View>
    );
  };
}

export default BaseMaritimeController;
