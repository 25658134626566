import React from "react";
import { View, StyleSheet } from "react-native";
import AppNavigator from "../../navigation/AppNavigator";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import RootController from "./RootController";

class RootAppController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  navItems = [
    // {
    //   route: routes.news,
    //   name: "News",
    //   icon: "newspaper-variant-outline",
    // },
    // // {
    // //   route: routes.news,
    // //   name: "Latest News",
    // //   icon: "newspaper-variant-outline",
    // // },
    // // {
    // //   route: routes.mynews,
    // //   name: "My News",
    // //   icon: "check-bold",
    // // },
    // {
    //   route: routes.marketing,
    //   name: "Mobile App",
    //   icon: "cellphone",
    // },
    {
      route: routes.settings,
      name: "Settings",
      icon: "cog",
    },
  ];

  componentDidMount() {
    super.componentDidMount();
  }

  handleLogout = () => {
    console.log("Signing out");
    this.context.updateUser(null);
  };

  showNavigation = () => {
    return (
      this.state.currentController !== routes.notFound &&
      this.state.currentController !== routes.marketing &&
      this.state.currentController !== routes.health &&
      this.state.currentController !== routes.guestapp &&
      this.state.currentController !== routes.crewapp &&
      this.state.currentController !== routes.pbxsystem &&
      this.state.currentController !== routes.starlink
    );
  };

  render() {
    return (
      <View style={styles.container}>
        {this.showNavigation() && (
          <NavigationBar
            navItems={this.navItems}
            currentController={this.state.currentController}
          />
        )}

        <AppNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAppController;
