import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtSettingsInputField from "../components/NtSettingsInputField";
import BaseController from "../../common/base/BaseController";
import client from "../../api/client";

class SettingsController extends BaseController {
  state = {
    apiUrl: global.apiUrl,
    apiAuth: global.apiAuth,
    stompUrl: global.stompUrl,
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showFooter={true}
        pageTitle={"Settings"}
      >
        <View style={{ marginTop: 20 }}>
          <NtText
            style={[styles.sectionTitle, { marginLeft: 10, marginBottom: 5 }]}
          >
            STOMP configuration
          </NtText>
          <NtSettingsInputField
            value={this.state.stompUrl}
            onChangeText={(text) => {
              this.setState({ stompUrl: text });
            }}
            onSave={() => {
              this.context.updateUser({
                ...this.context.user,
                stompUrl: this.state.stompUrl,
              });
              global.stompUrl = this.state.stompUrl;
            }}
          />
        </View>

        <View style={{ marginTop: 20 }}>
          <NtText
            style={[styles.sectionTitle, { marginLeft: 10, marginBottom: 5 }]}
          >
            API configuration
          </NtText>
          <NtSettingsInputField
            value={this.state.apiUrl}
            onChangeText={(text) => {
              this.setState({ apiUrl: text });
            }}
            onSave={() => {
              this.context.updateUser({
                ...this.context.user,
                apiUrl: this.state.apiUrl,
              });
              global.apiUrl = this.state.apiUrl;
              //finally will update the client
              client.setBaseURL(this.state.apiUrl);
            }}
          />
          <NtSettingsInputField
            containerStyle={{ marginTop: 10 }}
            value={this.state.apiAuth}
            onChangeText={(text) => {
              this.setState({ apiAuth: text });
            }}
            onSave={() => {
              this.context.updateUser({
                ...this.context.user,
                apiAuth: this.state.apiAuth,
              });
              global.apiAuth = this.state.apiAuth;
            }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  sectionTitle: {
    color: colors.lighGray,
    fontWeight: "600",
    fontSize: 13,
  },
});

export default SettingsController;
