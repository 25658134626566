import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";

class DashboardController extends React.Component {
  state = {};
  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Dashboard"}
        showFooter={true}
      ></NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default DashboardController;
