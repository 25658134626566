import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtAddonSection from "../components/NtAddonSection";
import NtHeader from "../components/NtHeader";
import NtHealthContactTracingSection from "../components/NtHealthContactTracingSection";
import NtHealthIncludedSection from "../components/NtHealthIncludedSection";
import NtHealthOutbreakSection from "../components/NtHealthOutbreakSection";
import NtHealthScreening from "../components/NtHealthScreening";
import NtStarlinkSection from "../components/NtStarlinkSection";
import NtInquirySection from "../components/NtInquirySection";
import NtStarlinkHeaderSection from "../components/NtStarlinkHeaderSection";
class MarketingStarlinkController extends BaseController {
  state = {};

  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHeader
            containerStyle={{ position: "relative" }}
            hoverEnabled={false}
          />
          <NtStarlinkSection />
          <NtInquirySection />
          
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingStarlinkController;
