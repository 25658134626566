import client from "./client";

export const authenticateUser = async (username, password) => {
  const response = await client.get(`/user/${username}`);

  return new Promise((resolve, reject) => {
    if (response.ok && response.data.password === password) {
      resolve({ ok: true, data: response.data });
    } else {
      resolve({ ok: false });
    }
  });
};
