import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtUserItem({ user, onPress }) {
  return (
    <NtTouchableEffect
      style={styles.container}
      onPress={() => {
        if (onPress) {
          onPress(user);
        }
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          justifyContent: "space-between",
        }}
      >
        <NtText style={styles.text}>{user.number}</NtText>
        <NtText style={[styles.text, { fontWeight: 400 }]}>{user.name}</NtText>
        <NtText style={[styles.text, { fontWeight: 400 }]}>{user.email}</NtText>
      </View>
      <View
        style={{ width: "100%", height: 1, backgroundColor: colors.lighter }}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    color: colors.lighGray,
    fontSize: 14,
    fontWeight: "700",
  },
});

export default NtUserItem;
