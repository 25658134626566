import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtTextInput from "../../components/NtTextInput";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMessageSendController from "../components/NtMessageSendController";
import { sendBroadcast } from "../../api/message";
import Logger from "../../common/utils/Logger";

import TextareaAutosize from "react-textarea-autosize";
import NtButton from "../../components/NtButton";

class BroadcastController extends React.Component {
  state = {
    message: "",
    sending: false,
  };

  handleBroadcaseSend = async () => {
    this.setState({ sending: true });
    const response = await sendBroadcast(this.state.message);
    Logger(
      "-------------- just sent the broadcast here is the response: ",
      response
    );
    this.setState({ sending: false, message: "" });
  };
  render() {
    return (
      <NtRootPageContainer
        showNavigation={true}
        showFooter={true}
        pageTitle={"Broadcast Messages"}
        pageSubtitle={"Send messages to all user"}
      >
        <View>
          <NtText style={[styles.text, { marginTop: 30 }]}>
            Enter message below and send to all users in the system.
          </NtText>

          <TextareaAutosize
            style={{
              fontSize: 14,
              borderColor: colors.lighGray,
              marginTop: 10,
            }}
            onChange={(e) => {
              this.setState({ message: e.target.value });
            }}
            value={this.state.message}
            minRows={6}
            placeholder="Message to send"
          />

          <NtButton
            containerStyle={{ width: 80, marginTop: 20 }}
            text={"Send"}
            isLoading={this.state.sending}
            onPress={() => {
              Logger("Will send the following text", this.state.message);
              this.handleBroadcaseSend();
            }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    color: colors.lighGray,
    fontWeight: "500",
  },
  searchButton: {
    height: 40,
    width: 40,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.lighGray,
    marginLeft: 5,
  },
});

export default BroadcastController;
